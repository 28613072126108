/* ********************************** CALIBRE FRONT ************************************/

@font-face {
  font-family: calibre;
  src: url(/static/media/CalibreThin.adc68d7f.otf);
  font-weight: 200;
}

@font-face {
  font-family: calibre;
  src: url(/static/media/CalibreLight.e482c8d6.otf);
  font-weight: 300;
}

@font-face {
  font-family: calibre;
  src: url(/static/media/CalibreMedium.75ad32e4.otf);
  font-weight: 400;
}

@font-face {
  font-family: calibre;
  src: url(/static/media/CalibreSemibold.85d899ed.otf);
  font-weight: 500;
}

@font-face {
  font-family: calibre;
  src: url(/static/media/CalibreBold.6feab952.otf);
  font-weight: 600;
}

@font-face {
  font-family: calibre;
  src: url(/static/media/CalibreBlack.8d48552b.otf);
  font-weight: 700;
}

/* ********************************** SFMono ************************************/

@font-face {
  font-family: SFMono;
  src: url(/static/media/SFMonoLight.761bd078.otf);
  font-weight: 400;
}

@font-face {
  font-family: SFMono;
  src: url(/static/media/SFMonoMedium.dd6296df.otf);
  font-weight: 500;
}

@font-face {
  font-family: SFMono;
  src: url(/static/media/SFMonoRegular.41b67f4d.otf);
  font-weight: 600;
}

@font-face {
  font-family: SFMono;
  src: url(/static/media/SFMonoSemibold.ae31eb44.otf);
  font-weight: 700;
}

@font-face {
  font-family: SFMono;
  src: url(/static/media/SFMonoBold.a587827f.otf);
  font-weight: 800;
}

@font-face {
  font-family: SFMono;
  src: url(/static/media/SFMonoHeavy.a20c143b.otf);
  font-weight: 900;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  font-family: calibre;
}

::-webkit-scrollbar-track {
  background: #0b182f;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #485770;
  border: 3px solid #0b182f;
  border-radius: 10px;
}

/* @media only screen and (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
} */

.imp {
  color: #64ffda;
}

imp {
  color: #64ffda;
}

.bg-clip-text {
  background: radial-gradient(
    circle,
    color-mix(in srgb, #8cb4e955 32%, transparent) 70%,
    #8cb4e955 100%
  );
  background-position-y: -200px;
  background-size: 100% 100%;
  height: 100%;
  left: 0;
  -webkit-mask-image: linear-gradient(90deg, #fff 1px, transparent 0),
    linear-gradient(180deg, #fff 1px, transparent 0);
  mask-image: linear-gradient(90deg, #fff 1px, transparent 0),
    linear-gradient(180deg, #fff 1px, transparent 0);
  -webkit-mask-position: calc(mod(100vw, 4rem) / 2) 0;
  mask-position: calc(mod(100vw, 4rem) / 2) 0;
  -webkit-mask-repeat: repeat;
  mask-repeat: repeat;
  -webkit-mask-size: 4rem 4rem;
  mask-size: 4rem 4rem;
  opacity: 0.59;
  position: fixed;
  top: 0;
  width: 100%;
}

@-webkit-keyframes LoadingFadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
    z-index: -9999;
  }
}

@keyframes LoadingFadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
    z-index: -9999;
  }
}

@-webkit-keyframes ImagePopup {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes ImagePopup {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@-webkit-keyframes bar-height {
  0% {
    height: 0%;
  }
  30% {
    height: 20%;
  }
  70% {
    height: 80%;
  }
  100% {
    height: 100%;
  }
}

@keyframes bar-height {
  0% {
    height: 0%;
  }
  30% {
    height: 20%;
  }
  70% {
    height: 80%;
  }
  100% {
    height: 100%;
  }
}

@-webkit-keyframes h-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes h-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes logo-fade-out {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes logo-fade-out {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@-webkit-keyframes FadeOut {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes FadeOut {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

