body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  font-family: calibre;
}

::-webkit-scrollbar-track {
  background: #0b182f;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #485770;
  border: 3px solid #0b182f;
  border-radius: 10px;
}

/* @media only screen and (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
} */

.imp {
  color: #64ffda;
}

imp {
  color: #64ffda;
}

.bg-clip-text {
  background: radial-gradient(
    circle,
    color-mix(in srgb, #8cb4e955 32%, transparent) 70%,
    #8cb4e955 100%
  );
  background-position-y: -200px;
  background-size: 100% 100%;
  height: 100%;
  left: 0;
  -webkit-mask-image: linear-gradient(90deg, #fff 1px, transparent 0),
    linear-gradient(180deg, #fff 1px, transparent 0);
  mask-image: linear-gradient(90deg, #fff 1px, transparent 0),
    linear-gradient(180deg, #fff 1px, transparent 0);
  -webkit-mask-position: calc(mod(100vw, 4rem) / 2) 0;
  mask-position: calc(mod(100vw, 4rem) / 2) 0;
  -webkit-mask-repeat: repeat;
  mask-repeat: repeat;
  -webkit-mask-size: 4rem 4rem;
  mask-size: 4rem 4rem;
  opacity: 0.59;
  position: fixed;
  top: 0;
  width: 100%;
}
