@keyframes LoadingFadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
    z-index: -9999;
  }
}

@keyframes ImagePopup {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes bar-height {
  0% {
    height: 0%;
  }
  30% {
    height: 20%;
  }
  70% {
    height: 80%;
  }
  100% {
    height: 100%;
  }
}

@keyframes h-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes logo-fade-out {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
